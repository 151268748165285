.couvertureCard {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 10px;
}

.couvertureCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}