.menu-card {
    cursor: pointer;
    width: 100%;
}

.menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1200px;
}

.menu-card img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.card-content {
    border-right: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    border-radius: 0 0 8px 8px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 500px) {
    .menu-card {
        max-width: 350px;
        width: 100%;
    }

}

@media screen and (min-width: 768px) {
    .menu-card {
        width: 45%;
    }
}
