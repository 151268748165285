.centered-axis-xy {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 49; /* Inférieur à z-index du modal */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    z-index: 50; /* Supérieur à z-index du overlay */
}

/*Animation au clik / hover du bouton*/
.close-button:hover, .close-button:focus, .close-button:active {
    transform: scale(1.2);
    transition: all 0.4s ease-in-out;
    color: #fff
}


@media (min-width: 520px) {
    .centered-axis-xy {
        width: 90%;
    }
}