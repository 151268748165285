.carousel {
    width: 100%;
}

.thumbs {
    display: none;
}

.carousel img {
    width: 100%;
    height: 400px;
}