@tailwind base;
@tailwind components;
@tailwind utilities;

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  padding: 10px;
  width: 5px;
  height: 5px;
}

/* Set the background color of the scrollbar */
::-webkit-scrollbar-track {
  background-color: rgba(241, 241, 241, 0.41);
  border-radius: 20px;
}

/* Set the color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 5px;
}

/* Keep the scrollbar always visible */
::-webkit-scrollbar-thumb:vertical {
  min-height: 50px;
}

:root {

  --breakpoint-small: 390px;
  --breakpoint-medium: 768px;
  --breakpoint-large: 1510px;

  /* Couleurs */
  --primary-color: #427bc7;
  --secondary-color: #6c757d;
  --tertiary-color: #ff6c00;
  --quaternary-color: rgba(94, 102, 0, 0.89);
  --quinary-color: #26260e;
  --white : #ffffff;
  --black: #000;
  --grey: #4D4D4D;

  /* Familles de polices */
  --font-family-heading: 'Lora', sans-serif;
  --font-family-body: 'Open Sans', sans-serif;

  /* Tailles de police pour téléphones */
  --font-size-heading1-mobile: 30px;
  --font-size-heading2-mobile: 26px;
  --font-size-heading3-mobile: 22px;
  --font-size-heading4-mobile: 18px;
  --font-size-body-mobile: 16px;

  /* Tailles de police pour tablettes */
  --font-size-heading1-tablet: 32px;
  --font-size-heading2-tablet: 24px;
  --font-size-heading3-tablet: 20px;

  --font-size-body-tablet: 18px;

  /* Tailles de police pour ordinateurs portables */
  --font-size-heading1-laptop: 40px;
  --font-size-heading2-laptop: 32px;
  --font-size-heading3-laptop: 24px;
  --font-size-heading4-laptop: 20px;

  --font-size-body-laptop: 18px;

  /* Espacements */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;

  --padding-small: 3px;
  --padding-medium: 6px;
  --padding-large: 20px;

  --padding-body : 20px;

  /* Bordures */
  --border-radius-small: 5px;
  --border-radius-medium: 15px;
  --border-radius-large: 30px;

  /* Autres variables */
  --header-height: 60px;
  --footer-height: 80px;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Variables d'écran */
:root {
  --screen-xs: 520px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1536px;
}

/* Variables de couleur */
:root {
  --color-primaire: #1d252d;
  --color-secondaire: #1c1b1b;
  --color-white: #ffffff;
  --color-tertiary: #427bc7;
  --color-black: #000000;
  --color-bgGray: rgba(241, 241, 241, 0.45);
  --color-grayLight: rgba(0, 0, 0, 0.5);
  --color-ligth: rgba(73,71,71,0.78);
  --color-dark: #0c1226;
  --color-grayLighter: #f1f1f1;
  --color-danger: #ff0000;
}

/* Variables de taille de police */
:root {
  --font-size-xxs: 0.7rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.25rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 4rem;
  --font-size-7xl: 5rem;
}

/* Variables de famille de polices */
:root {
  --font-family-open: 'Open Sans', sans-serif;
}

/* Variables de rayon de bordure */
:root {
  --border-radius-none: 0;
  --border-radius-sm: 0.125rem;
  --border-radius-default: 0.25rem;
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 0.75rem;
}

html {
  font-size: 1rem;
}
/* index.css */
body {
  font-family: var(--font-family-body);
  margin: 0;
  padding: 0;
  font-size: 15px;
}

button {
  cursor: pointer;
  text-transform: uppercase;
}

hr {
  margin: 20px auto!important;
}

a {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.bold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-heading);
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }

}


